import $ from 'jquery';
import locomotiveInit from './locomotive.js';

const notFound = () => {

    $(window).on("load", function () {
        locomotiveInit();
    });
};

export default notFound;