import LocomotiveScroll from 'locomotive-scroll';

const locomotiveInit = () => {
    const scroll = new LocomotiveScroll({
        el: document.querySelector('[data-scroll-container]'),
        smooth: true,
        direction: 'vertical',
        offset: "30%",
        getSpeed: true,
        class: 'visible',
        reverse: false
    });

    scroll.on('scroll', (position) => {
        if(position.scroll.y > 0) {
            $("header").addClass("header-smaller");
        } else {
            $("header").removeClass("header-smaller");
        }
    });

    return scroll;
};

export default locomotiveInit;