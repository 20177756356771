import $ from 'jquery';
import { gsap } from 'gsap/all';
import locomotiveInit from './locomotive.js';

const newsPage = () => {
    let newsTL = gsap.timeline();

    $(window).on("load", function () {
        new SplitText(".text-line", {
            type: "lines"
        });

        locomotiveInit();
    });
};

export default newsPage;