import $ from 'jquery';
import { gsap, Power4, Elastic } from 'gsap/all';
import locomotiveInit from './locomotive.js';

const productPage = () => {
    let productTL = gsap.timeline();

    $(window).on("load", function () {
        const productGallerySlider = new Swiper(".product-gallery-swiper", {
            loop: true,
            slidesPerView: 'auto',
            pagination: {
                el: '.swiper-pagination',
                clickable: true
            },
            centeredSlides: true,
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            }
        });

        new SplitText(".text-line", {
            type: "lines"
        });

        gsap.to(".product-hero .title", {
            autoAlpha: 1
        });

        locomotiveInit();

        if ($(".product-box").length) {
            gsap.to(".product-box-circle-first", {
                duration: 1.2,
                opacity: 1,
                // x: "-50%",
                // y: "-50%",
                scaleX: 1,
                scaleY: 1,
                delay: 0.125,
                transformOrigin: "center center",
                ease: Elastic.easeOut.config(1, 0.65)
            });
            gsap.to(".product-box-circle-second", {
                duration: 1.2,
                opacity: 1,
                scaleX: 1,
                scaleY: 1,
                delay: 0.25,
                transformOrigin: "center center",
                ease: Elastic.easeOut.config(1, 0.65)
            });
            gsap.to(".product-box img", {
                duration: 1.2,
                opacity: 1,
                y: 0,
                ease: Power4.ease
            })
        }
    });
};

export default productPage;