import $ from 'jquery';
const productsHomeSlider = () => {
    $(window).on("load", function () {
        const products_swiper = new Swiper('.products-swiper', {
            loop: true,
            slidesPerView: 3,
            speed: 800,
            centeredSlides: true,
            pagination: {
                el: '.swiper-pagination',
                clickable: true
            },
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },
            on: {
                slideChange: function () {
                    $('.swiper-slide .explore-product').remove();
                    const activeSlide = this.slides[this.activeIndex];
                    $(activeSlide).append('<span class="explore-product">Explore Products</span>');
                }
            },
            breakpoints: {
                0: {
                    slidesPerView: 1,
                },
                578: {
                    slidesPerView: 2,
                },
                768: {
                    slidesPerView: 3,
                }
            }
        });
    });
}
export default productsHomeSlider;