import "@babel/polyfill";
import $ from 'jquery';
import loader from './__modules/loader';
import mobile from "./__modules/mobile";
import productsHomeSlider from "./__modules/productsHomeSlider";
import productSlider from "./__modules/productSlider";
import waterLifePage from "./__modules/waterLifePage";
import newsPage from "./__modules/news";
import articlePage from "./__modules/article";
import contactPage from "./__modules/contact";
import productPage from "./__modules/productPage";
import aboutPage from "./__modules/about";
import productsPage from "./__modules/productsPage";
import notFound from "./__modules/notfound";
import cursor from "./__modules/cursor";

document.addEventListener('DOMContentLoaded', () => {
	if ($(".loader").length) {
		loader();
	}

	mobile();

	if ($(".products-swiper").length) {
		productsHomeSlider();
	}

	if ($(".products").length) {
		productsPage();
	}

	if ($(".product-swiper")) {
		productSlider();
	}

	if ($(".product").length) {
		productPage();
	}

	if ($(".water-life").length) {
		waterLifePage();
	}

	if ($(".about").length) {
		aboutPage();
	}

	if ($(".news").length) {
		newsPage();
	}

	if ($(".article-item").length) {
		articlePage();
	}

	if ($(".contact").length) {
		contactPage();
	}

	if ($(".notfound").length) {
		notFound();
	}

	if ($(".circle-cursor").length) {
		cursor();
	}
});

window.addEventListener('load', () => {
	
});

window.addEventListener('resize', () => {
});

window.addEventListener('scroll', () => {
});