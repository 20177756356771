import { gsap, Power4, Expo } from 'gsap/all';
require("../__vendor/SplitText.min.js");
import locomotiveInit from './locomotive.js';

const loader = () => {

    const createGSTimeline = () => gsap.timeline({ paused: true });
    const createSplitText = (selector) => new SplitText(selector, { type: "lines" });

    let loaderTL = createGSTimeline();
    let textLines = createSplitText(".hero .title, .text-line");
    let scroll = null;

    class Revealer {
        constructor(el, options = {}) {
            this.options = {
                angle: 0,
                ...options
            };

            this.DOM = {
                el: el,
                inner: el.firstElementChild,
                get reverse() {
                    return this.inner.querySelector('.content__reverse');
                }
            };

            this.applyTransformations();
        }

        applyTransformations() {
            const { angle } = this.options;
            const { inner, el, reverse } = this.DOM;

            const width = `calc(100vw * ${Math.abs(Math.cos(angle * Math.PI / 180))} + 100vh * ${Math.abs(Math.sin(angle * Math.PI / 180))})`;
            const height = `calc(100vw * ${Math.abs(Math.sin(angle * Math.PI / 180))} + 100vh * ${Math.abs(Math.cos(angle * Math.PI / 180))})`;
            const transform = `rotate3d(0,0,1,${angle}deg)`;

            inner.style.width = width;
            inner.style.height = height;
            el.style.transform = transform;

            if (reverse) {
                TweenMax.set(reverse, { rotation: -1 * angle });
            }
        }
    }

    const querySelector = (selector) => document.querySelector(selector);
    const querySelectorAll = (selector) => [...document.querySelectorAll(selector)];

    let overlays = [],
        overlayElems = querySelectorAll('.overlay'),
        overlaysTotal = overlayElems.length,
        mountain = querySelector(".mountain"),
        loader = querySelector(".loader"),
        overlayItems = querySelectorAll(".overlay"),
        loaderInner = querySelector(".loader-inner"),
        loaderLogo = querySelector(".loader-logo"),
        bottle = querySelector(".bottle"),
        bottleOutline = querySelector(".bottle-outline"),
        progressContainer = querySelector('.progress-container'),
        progressFill = querySelector('.progress-fill'),
        progressText = querySelector('.progress-text'),
        circleFiller = querySelector('.circle-filler'),
        progressBg = querySelector('.progress-bg'),
        //explore = querySelector('.explore'),
        header = querySelector('.header-transparent');

    overlayElems.forEach((overlay, i) => overlays.push(new Revealer(overlay, { angle: i % 2 === 0 ? -3 : 3 })));

    loaderTL
    .to(mountain, {
        opacity: 1,
        duration: 0.5
    }).set(loaderLogo, {
        className: "+= loader-logo loader-logo-show"
    }).to(bottleOutline, {
        duration: 1,
        clipPath: "polygon(0 0, 100% 0, 100% 100%, 0 100%)",
    }).set(loaderLogo, {
        className: "+= loader-logo loader-logo-show loader-logo-fit"
    }).to(bottle, {
        duration: 1,
        ease: Power4.ease,
        clipPath: "polygon(0 0, 100% 0, 100% 100%, 0 100%)",
        onComplete: () => {
            setTimeout(() => {
                loaderLogo.remove();
                bottleOutline.remove();
            }, 500);
        }
    }, 0.65).to(loaderInner, {
        scale: 0.5,
        duration: 0.85,
        ease: Power4.ease
    }, 1.5).to(progressContainer, {
        scale: 1,
        duration: 0.5,
        opacity: 1,
        ease: "back.in(3)"
    }, 1.8).to(progressText, {
        opacity: 1,
        duration: 0.5,
        ease: Power4.ease
    }, 2).to(progressFill, {
            strokeDashoffset: 0,
            duration: 1.5, 
            ease: Power4.ease,
            onUpdate: function () {
                var percent = Math.round((251 - parseFloat(progressFill.style.strokeDashoffset)) / 251 * 100);
                progressText.textContent = percent + " %";
                circleFiller.style.transform = `scale(${percent / 100})`;
                progressBg.style.transform = `scale(${percent / 100})`;
            }
    }, 2.275).to(loaderInner, {
        scale: 0,
        duration: 0.6,
        opacity: 0,
        ease: "back.in(3)"
    }, 3.925).to(progressContainer, {
        scale: 0.35,
        duration: 0.35,
        opacity: 0,
        ease: "back.in(3)"
    }, 4).to(progressText, {
         opacity: 0
    }, 4).to(mountain, {
        duration: 0.75,
        opacity: 0
    }, 4.5);

    let t = 0;
    for (let i = 0; i <= overlaysTotal - 1; ++i) {
        t = 0.2 * i + 0.2
        loaderTL.to(overlays[overlaysTotal - 1 - i].DOM.inner, 1.2, {
            delay: 4.1,
            ease: Expo.easeInOut,
                y: '-100%',
                onComplete: () => {
                    if(i===2) {
                        loader.remove();
                        overlayItems.forEach(overlayItem => {
                            overlayItem.remove();
                        });
                    }
                }
            }, t);
    }

    

    if($(window).width() > 991) {
        loaderTL.to(".hero .layers", {
            scale: 1,
            opacity: 1,
            duration: 1,
            ease: Power4.ease
        }, 5.2);
    } else {
        loaderTL.to(".hero .hero-mobile", {
            scale: 1,
            opacity: 1,
            duration: 1,
            ease: Power4.ease
        }, 5.2);
    }

    loaderTL.to(".hero .title > div", {
        y: 0,
        opacity: 1,
        stagger: 0.2,
        duration: 1
    }, 5.425).to(".hero", {
        backgroundColor: "#e5e9ec",
        duration: 3
    }, 5.5).to(".header-transparent ul li", {
        opacity: 1,
        stagger: 0.05,
        duration: 0.6,
        ease: Power4.ease
    }, 5.5).to('.header-transparent .logo, .header-transparent .burger-menu', {
        opacity: 1,
        scale: 1,
        duration: 0.6
    }, 5.25);

    setTimeout(() => {
        loaderTL.play();
        scroll = locomotiveInit();

        scroll.on('scroll', (obj) => {
            if (obj.scroll.y > 0) {
                header.classList.add('header-scrolled');
            } else {
                header.classList.remove('header-scrolled');
            }

            // const scrollElements = obj.currentElements;
            // console.log(obj);
            // for (let key in scrollElements) {
            //     console.log(scrollElements[key].el);
            //     if (scrollElements[key].el.classList.contains('qualities')) {
            //         if (scrollElements[key].inView) {
            //             qualitiesEnter();
            //         }
            //     }
            // }
        });

        // scroll.on("call", (func, state, event) => {
        //     console.log("AAA");
        //     try {
        //         console.log("call", func, state, event);
        //         switch (func) {
        //             case "qualities-sec":
        //                 qualitiesEnter(state, event);
        //                 break;
        //             // Add more cases as needed
        //             default:
        //                 console.log("Unhandled function call:", func);
        //         }
        //     } catch (error) {
        //         console.error("Error in call event handler:", error);
        //     }
        // });

        function observeClassChanges(element, callback) {
            const observer = new MutationObserver((mutationsList) => {
                for (let mutation of mutationsList) {
                    if (mutation.type === 'attributes' && mutation.attributeName === 'class') {
                        callback(mutation.target);
                    }
                }
            });

            observer.observe(element, { attributes: true });
        }
        const animateMeElement = document.querySelector('.quality-section');
        observeClassChanges(animateMeElement, (element) => {
            if (element.classList.contains('visible')) {
                qualitiesEnter();
            }
        });

        if (document.querySelector('.explore')) {
            document.querySelector('.explore').addEventListener("click", (e) => {
                e.preventDefault(); 
                const targetElement = document.getElementById("nature");
                scroll.scrollTo(targetElement);
            });
        }
    }, 500);

    document.addEventListener('mousemove', parallax);

    document.getElementById('video-play').addEventListener('click', function () {
        const video = document.getElementById('video');
        const videoPlayButton = document.getElementById('video-play');

        videoPlayButton.style.opacity = '0';
        video.style.opacity = '1';

        setTimeout(function () {
            videoPlayButton.style.display = 'none';
            video.play();
        }, 500);
    });

    function parallax(event) {
        const layers = document.querySelectorAll('.layers img');
        layers.forEach((layer, index) => {
            const speeds = [
                { x: 2, y: 2 },
                { x: 1.75, y: 1.75 },
                { x: 1.5, y: 1.5 },
                { x: 1.25, y: 1.25 },
                { x: 2.0, y: 2.2 },
                { x: 1.2, y: 1.0 },
                { x: 1.4, y: 1.4 },
                { x: 1.6, y: 1.6 },
                { x: 1.8, y: 1.2 },
                { x: 2.0, y: 1.0 }
            ];

            const xSpeed = speeds[index].x;
            const ySpeed = speeds[index].y;

            const x = (window.innerWidth / 2 - event.pageX) * xSpeed / 100;
            const y = (window.innerHeight / 2 - event.pageY) * ySpeed / 100;

            gsap.to(layer, {
                x: x,
                y: y,
                ease: "power2.out"
            });
        });
    }

    function qualitiesEnter() {
        let qualitiesTl = gsap.timeline({
            paused: true
        });

        qualitiesTl.set(".shape-polygon", {
            attr: {
                class: 'shape-polygon shape-polygon-show'
            }
        }).set(".shape-polygon-outside", {
            attr: {
                class: 'shape-polygon-outside shape-polygon-show'
            }
        }).to(".shape-line", {
            stagger: 0.05,
            clipPath: "polygon(0 0, 100% 0, 100% 100%, 0% 100%)",
        }, 0.1).to(".shape-figure", {
            stagger: 0.05,
            opacity: 1,
            y: 0,
            duration: 1,
            ease: Power4.ease
        }, 1).set(".shape-product", {
            attr: {
                class: 'shape-product shape-product-show'
            }
        }, 0.85).set(".qualities-title", {
            attr: {
                class: 'qualities-title qualities-title-show'
            }
        }, 1.2).to(".shape-text", {
            stagger: 0.05,
            y: 0,
            opacity: 1,
            ease: Power4.ease
        }, 1.2).to(".quality-section img", {
            scale: 1,
            x: "-50%",
            y: "-50%",
            opacity: 1,
        }, 1.4).set(".qualities-shape", {
            attr: {
                class: 'qualities-shape qualities-shape-show'
            }
        }, 1.4);

        qualitiesTl.play();
    }
};

export default loader;