import $ from 'jquery';
import { gsap } from 'gsap/all';
import locomotiveInit from './locomotive.js';

const articlePage = () => {
    let articleTl = gsap.timeline();

    $(window).on("load", function () {
        new SplitText(".text-line", {
            type: "lines"
        });

        const articleGallerySlider = new Swiper(".product-gallery-swiper", {
            loop: true,
            slidesPerView: 'auto',
            pagination: {
                el: '.swiper-pagination',
                clickable: true
            },
            centeredSlides: true,
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            }
        });
        
        locomotiveInit();
    });
};

export default articlePage;