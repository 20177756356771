const cursor = () => {
    const circleCursor = document.querySelector('.circle-cursor');
    const linksAndButtons = document.querySelectorAll('a, .swiper-button-prev, .swiper-button-next');

    document.addEventListener('mousemove', e => {
        circleCursor.style.left = `${e.clientX}px`;
        circleCursor.style.top = `${e.clientY}px`;
    });

    function addHoverEffect(elements) {
        elements.forEach(element => {
            element.addEventListener('mouseover', () => {
                circleCursor.style.transform = 'translate(-50%, -50%) scale(2.35)';
                circleCursor.style.opacity = '0.75';
                circleCursor.style.backgroundColor = 'transparent';
            });

            element.addEventListener('mouseout', () => {
                circleCursor.style.transform = 'translate(-50%, -50%) scale(1)';
                circleCursor.style.opacity = '1';
                circleCursor.style.backgroundColor = '#244684';
            });
        });
    }

    addHoverEffect(linksAndButtons);
};

export default cursor;