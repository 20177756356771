import $ from 'jquery'; 
const productSlider = () => {
    $(window).on("load", function () {
        const product_swiper = new Swiper('.product-swiper', {
            loop: true,
            slidesPerView: 1,
            centeredSlides: true,
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            }, pagination: {
                el: '.swiper-pagination',
                clickable: true
            }
        });
    });
}

export default productSlider;