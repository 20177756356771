import $ from 'jquery';
import { gsap, Elastic, Power4 } from 'gsap/all';
import locomotiveInit from './locomotive.js';

const productsPage = () => {
    let aboutTl = gsap.timeline();

    $(window).on("load", function () {
        new SplitText(".text-line", {
            type: "lines"
        });

        let scroll = locomotiveInit();

        scroll.on("call", (func, state, event) => {
            switch(func) {
                case "premium-circle":
                    circleEnter(state, event);
            }
        });
    });
};

function circleEnter(state, event) {
    gsap.to(".mokne-premium-circle-first", {
        duration: 1.2,
        opacity: 1,
        scaleX: 1,
        scaleY: 1,
        delay: 0.125,
        transformOrigin: "center center",
        ease: Elastic.easeOut.config(1, 0.65)
    });
    gsap.to(".mokne-premium-circle-second", {
        duration: 1.2,
        opacity: 1,
        scaleX: 1,
        scaleY: 1,
        delay: 0.25,
        transformOrigin: "center center",
        ease: Elastic.easeOut.config(1, 0.65)
    });
    gsap.to(".mokne-premium-img img", {
        duration: 1.2,
        opacity: 1,
        y: 0,
        ease: Power4.ease
    })
}

export default productsPage;